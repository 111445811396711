<template>
  <div class="fluid" id="positionList">
    <div>
      <div class="position-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                JABATAN
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="position-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div>
                    <div style="width: 120px">
                      <v-select
                        :item-disabled="disableItem"
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 8, name: 'Export Item Terpilih' },
                          { id: 9, name: 'Export Semua Item' },
                          { id: 1, name: 'Delete' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <v-dialog v-model="dialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size:12px;margin-left:20px;"
                        @click="addPosition"
                        :disabled="loading"
                      >
                        Tambah jabatan
                      </v-btn>
                    </template>

                    <v-form
                      :disabled="loading"
                      ref="entryForm"
                      @submit.prevent="submit"
                      style="position: relative;"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Tambah Jabatan
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position:relative; top:20px;"
                          >
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Nama Jabatan
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0;">
                                <v-text-field
                                  v-model="form.name"
                                  outlined
                                  :rules="nameRules"
                                />
                              </v-col>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="close"
                            :loading="loading"
                          >
                            Batal
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            type="submit"
                            text
                            outlined
                            :loading="loading"
                          >
                            Simpan
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                  <div class="position-list-toolbar-1">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="cari jabatan"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      style="position: relative; top: 15px; width: 10px;
                        margin-left: 10px;"
                      @keyup.enter="searchEnter"
                      :disabled="loading"
                    ></v-text-field>
                    <div
                      style="margin-left:20px;display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                        @click="searchEnter"
                        :disabled="loading"
                      >
                        search
                      </v-btn>
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="position-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="58vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="false"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
import XLSX from 'xlsx'
export default {
  name: 'position',
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      itemsPerPage: 10
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Nama Jabatan',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Buat',
        value: 'createdAt',
        align: 'left',
        sortable: false
      }
      // {
      //   text: 'Dibuat Oleh',
      //   value: 'created_by_name',
      //   align: 'left',
      //   sortable: false
      // }
    ],
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'add',
      id: '',
      name: ''
    },
    nameRules: []
  }),

  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company.id
    await this.getDataFromApi()
    this.setAction()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    close() {
      this.nameRules = []
      this.form = {
        act: 'add',
        id: '',
        name: ''
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.selectedData.length < 2) {
            this.delete()
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, untuk saat ini Anda hanya dapat menghapus 1 data pada satu waktu',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = null
            }, 200)
          }
          break
        case 8:
          this.exportItem()
          break
        case 9:
          this.exportItem()
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      return await new Promise(resolve => {
        axios
          .get(
            `${this.hrsApi}master/universal/employeelevel/list?keyword=${
              this.paramAPI.keyword
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }`
          )
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    addPosition() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    rowClick(pItem) {
      this.form.act = 'update'
      this.form.id = pItem.id
      this.form.name = pItem.name
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}master/universal/employeelevel/delete/${this.selectedData[0].id}`
            )
            .then(async res => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = 0
        }
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    submit() {
      this.nameRules = [v => !!v || 'Name is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        name: this.form.name
      }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}master/universal/employeelevel/save`, form)
        .then(async res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    disableItem(item) {
      if (item.id !== 9) {
        return this.selectedData.length === 0
      }
      if (item.id === 9) {
        return this.selectedData.length !== 0
      }
    },
    exportItem() {
      this.showMsgDialog('question', 'Export sekarang', true).then(res => {
        if (res.isConfirmed) {
          this.fetchExport()
        } else if (res.dismiss === 'cancel') {
          this.setAction()
        } else if (res.dismiss === 'backdrop') {
          this.setAction()
        }
      })
    },
    async fetchExport() {
      this.loading = true
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then(res => {
          this.selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              this.selectedData.push({
                id: response[i].id,
                name: response[i].name
              })
            }
            this.exportNow()
          } else {
            this.selectedData = []
          }
          return null
        })
        .catch(error => {
          console.log(error)
          return null
        })
    },
    exportNow() {
      const arrData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        const param = {
          id: this.selectedData[i].id,
          name: this.selectedData[i].name
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'list_jabatan.xlsx')

      setTimeout(() => {
        this.selectedData = []
        this.actionValue = null
        this.loading = false
      }, 1000)
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    }
  }
}
</script>
<style lang="scss">
#positionList {
  position: relative;
  .position-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .position-list-toolbar-1 {
      .position-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .position-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #positionList {
    .position-list-div {
      .position-list-toolbar-1 {
        .position-list-toolbar-1 {
          display: none;
        }
      }
      .position-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
